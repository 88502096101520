import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '@storybook';

import { adjustInputWidth, isValidAmount } from 'utils';
import {
	AmountSentToRecipientState,
	SelectedRecipientForPaymentState,
} from '../../stores';

import './enter-amount.scss';

interface IEnterAmount {
	handleReview: () => void;
}

export const EnterAmount: FC<IEnterAmount> = ({ handleReview }) => {
	// global state
	const { name } = useRecoilValue(SelectedRecipientForPaymentState);
	const [amountToSend, setAmountToSent] = useRecoilState(
		AmountSentToRecipientState
	);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e?.target ?? {};
			if (isNaN(Number(value))) return;
			if (value.includes('.')) setAmountToSent(value || '0');
			else {
				const numValue = parseFloat(value || '0');
				setAmountToSent(numValue || '0');
			}
		},
		[setAmountToSent]
	);

	const isDisabled = useMemo(
		() => !isValidAmount(amountToSend as string) || !Number(amountToSend),
		[amountToSend]
	);

	return (
		<div className="EnterAmount">
			<div className="EnterAmount__sendTo">Send to {name}</div>
			<div className="EnterAmount__amount">
				<span>$</span>
				<input
					type="text"
					value={amountToSend}
					className="EnterAmount__input-amount"
					autoFocus={true}
					onFocus={(e) => e.target.select}
					onChange={handleChange}
					maxLength={10}
					onInput={adjustInputWidth}
				/>
			</div>
			<div className="EnterAmount__other-info">
				<div>From D ESS 0001 (x8612)</div>
				<div>$2,000.23 available</div>
			</div>
			<div className="EnterAmount__review">
				<Button
					label={'Review'}
					handleClick={handleReview}
					type={'button__filled button__filled--waiting button__large'}
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};
