import { Navigate, Route, Routes } from 'react-router-dom';

import { Main } from 'views';
import { ROUTES } from './constants';

export const AllRoutes = () => {
	return (
		<Routes>
			<Route path="*" element={<Navigate to={ROUTES.DEFAULT} />} />
			<Route path={ROUTES.DEFAULT} element={<Main />} />
		</Routes>
	);
};
