export const RECIPIENT_LIST = [
	{
		key: 'A',
		name: 'Arun Kumar',
		phone: '+918283847779',
	},
	{
		key: 'SS',
		name: 'Shubham Shukla',
		phone: '+91739235825',
	},
	{
		key: 'AK',
		name: 'Archana Kumari',
		phone: '+919634798092',
	},

	{
		key: 'MT',
		name: 'Marshal Tavakar',
		phone: '+919799966728',
	},
	{
		key: 'AT',
		name: 'Alina Trombley',
		phone: '+14157416782',
	},
	{
		key: 'JD',
		name: 'Jim Delaney',
		phone: '+16127207022',
	},
	{
		key: 'JW',
		name: 'John Whitaker',
		phone: '+16122401819',
	},
];
