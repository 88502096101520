import { FC } from 'react';

// import { Image } from '../image';

import './header.scss';

interface IHeader {
	logo?: string;
	loading?: boolean;
}

export const Header: FC<IHeader> = () => {
	return (
		<div className={'header-container'}>
			<div className={'logo'}></div>
		</div>
	);
};
