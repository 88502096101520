import { FC } from 'react';

import './screen-skelton.scss';

interface IScreenSkelton {
	header?: JSX.Element;
	body?: JSX.Element | any;
	footer?: JSX.Element | null;
	sideBar?: JSX.Element | null;
}

export const ScreenSkelton: FC<IScreenSkelton> = ({
	// header,
	// sideBar,
	body,
	footer,
}) => {
	return (
		<div className="skelton-wrapper">
			{/* <div className="skelton-wrapper__header">{header}</div> */}
			<div
				className="skelton-wrapper__body"
				style={{ marginBottom: footer ? '50px' : '0px' }}
			>
				{/* {sideBar} */}
				{body}
			</div>
			{footer && <div className="skelton-wrapper__footer">{footer}</div>}
		</div>
	);
};
