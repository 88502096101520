/* eslint-disable no-console */
import { useRecoilValue } from 'recoil';
import { Address, GoogleLocation, UserDetailsState } from 'views/send-payment/stores';

import './recipient-details.scss';
import { Button, Image } from '@storybook';
import { FC, useEffect, useMemo, useState } from 'react';
import { useGetLocationDetails } from '../use-location';

interface IReviewAndSend {
	handleProceed: () => void;
	handleReject: () => void;
}

export const RecipientDetails: FC<IReviewAndSend> = ({
	handleProceed,
	handleReject,
}) => {
	const userDetails = useRecoilValue(UserDetailsState);
	const googleLocation = useRecoilValue(GoogleLocation);
	const { customer } = useMemo(()=>userDetails ?? {},[userDetails]);
	const {latitude,longitude} = useMemo(()=>customer?.device_info?.location ?? {},[customer?.device_info?.location])
	console.log('userDetails', userDetails);

	const [, setLocation] = useState('Location...');

	const { formatted_address = '' } = useMemo(
		() => googleLocation?.address ?? ({} as Address),
		[googleLocation?.address]
	);

	const { getGeoInfo } = useGetLocationDetails();

	useEffect(() => {
		if (
			latitude &&
			longitude &&
			!googleLocation
		) {
			getGeoInfo({
				lat: latitude,
				lng: longitude,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetch(
			'https://nominatim.openstreetmap.org/reverse?format=json&lat=' +
				customer?.device_info?.location?.latitude +
				'&lon=' +
				customer?.device_info?.location?.longitude
		)
			.then((response) => response.json())
			.then((data) => {
				if (data) {
					setLocation(data.display_name);
				}
			})
			.catch(() => {
				// handle error
				setLocation('--');
			});
	}, [customer]);

	return (
		<div className="details-wrapper">
			{customer?.status === 'rejected' ? (
				<div className="details-wrapper__reject-wrapper">
					<i className="ri-error-warning-line details-wrapper__recject-icon"></i>
					<div className="details-wrapper__reject-heading">
						Transaction cancelled
					</div>
					<div className="details-wrapper__reject-subheading">
						Unfortunately, the recipient has declined to undergo biometric
						authentication, which is required for this transaction. As a result,
						the transaction has been cancelled.
					</div>
				</div>
			) : (
				<>
					<div className="details-wrapper__heading">
						Review the recipient details and determine if you wish to proceed
						with this transaction.
					</div>
					<div>
						{userDetails?.customer?.user_image ? (
							<Image
								className="details-wrapper__image"
								url={userDetails?.customer?.user_image}
							/>
						) : (
							<Image
								className="details-wrapper__image"
								fileName="AvatarDefault-Light.svg"
							/>
						)}
					</div>
					<div className="details-wrapper__out-box">
						<div className="details-wrapper__box">
							<div className="details-wrapper__label">Name :</div>
							<div className="details-wrapper__label-value">
								{userDetails?.user?.name ?? '--'}
							</div>
						</div>
						<div className="details-wrapper__box">
							<div className="details-wrapper__label">E-mail :</div>
							<div className="details-wrapper__label-value">
								{userDetails?.user?.email_id ?? '--'}
							</div>
						</div>
						<div className="details-wrapper__box">
							<div className="details-wrapper__label">Mobile :</div>
							<div className="details-wrapper__label-value">
								{userDetails?.user?.phone_no ?? '--'}
							</div>
						</div>
						<div className="details-wrapper__box">
							<div className="details-wrapper__label">Location :</div>
							<div className="details-wrapper__label-value" title={formatted_address}>
								{' '}
								{formatted_address}
							</div>
						</div>
					</div>
					{customer?.status === 'declined' && (
						<div className="details-wrapper__danger-text">
							Note: Recipient cancelled the transaction request.
						</div>
					)}

					<div className="details-wrapper__btn-box">
						<div className="btn-wrapper">
							<Button
								label={'Cancel transaction'}
								handleClick={() => handleReject()}
								type={
									'button__filled button__filled--danger button__large btn-details cancel_btn-box-border'
								}
							/>
						</div>
						<div className="btn-wrapper">
							<Button
								label={'Next'}
								handleClick={() => handleProceed()}
								type={
									'button__filled button__filled--waiting button__large btn-details'
								}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
