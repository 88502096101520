/* eslint-disable no-console */
import { FC, useEffect, useMemo, useRef } from 'react';
import './loading-screen.scss';
import { useRecoilValue } from 'recoil';
import {
	IsRecipientDoneState,
	SelectedRecipientForPaymentState,
	UserDetailsState,
} from 'views/send-payment/stores';
import {
	RECIPIENT_SENT_MESSAGE,
	SENDER_SENT_MESSAGE,
} from 'constant/constants';

interface ILoading {
	handleResend?: () => void;
}

export const LoadingScreen: FC<ILoading> = () => {
	const IsRecipientDone = useRecoilValue(IsRecipientDoneState);
	const { key, name, phone } = useRecoilValue(SelectedRecipientForPaymentState);
	const IsRecipientDoneRef = useRef(IsRecipientDone);
	const userDetails = useRecoilValue(UserDetailsState);
	const { sender } = userDetails ?? {};

	useEffect(() => {
		IsRecipientDoneRef.current = IsRecipientDone;
	}, [IsRecipientDone]);

	const renderMessage = useMemo(() => {
		if (!IsRecipientDoneRef.current) {
			return RECIPIENT_SENT_MESSAGE;
		} else {
			return SENDER_SENT_MESSAGE;
		}
	}, [IsRecipientDoneRef]);

	const renderRecipientCard = useMemo(() => {
		if (!IsRecipientDoneRef.current) {
			return (
				<div className="ReviewAndSend__recipient-card">
					<div className="ReviewAndSend__recipient-initials">{key}</div>
					<div className="ReviewAndSend__recipient-name">{'to ' + name}</div>
					<div className="ReviewAndSend__recipient-phone">{'at ' + phone}</div>
					<div className="loader-waiting"></div>
				</div>
			);
		} else {
			return (
				<div className="ReviewAndSend__recipient-card">
					<div className="ReviewAndSend__recipient-initials">
						{sender?.name.charAt(0) ?? '--'}
					</div>
					<div className="ReviewAndSend__recipient-name">{'to you'}</div>
					<div className="ReviewAndSend__recipient-phone">
						{'at ' + sender?.phone_no ?? '--'}
					</div>
					<div className="loader-waiting"></div>
				</div>
			);
		}
	}, [IsRecipientDoneRef, sender, key, name, phone]);

	const renderheading = useMemo(() => {
		if (!IsRecipientDoneRef.current) {
			return 'Identity verification link sent';
		} else {
			return 'Text message sent to you';
		}
	}, [IsRecipientDoneRef]);

	const renderResendButton = useMemo(() => {
		if (IsRecipientDoneRef.current) {
			return (
				// <div onClick={handleResend} className="loading-section__resend-wrapper">
				// 	Didn’t receive verification text?
				// 	<span className="loading-section__resend-btn">Resend</span>
				// </div>
				<></>
			);
		} else {
			return <></>;
		}
	}, [IsRecipientDoneRef]);

	return (
		<div className="loading-section">
			<p className="loading-section__heading">{renderheading}</p>
			<div className="image-boxh">{renderRecipientCard}</div>

			<div className="loading-section__desp">{renderMessage}</div>
			{renderResendButton}
		</div>
	);
};
