import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, Loader } from '@storybook';

import {
	AmountSentToRecipientState,
	SelectedRecipientForPaymentState,
} from '../../stores';

import './review-send.scss';

interface IReviewAndSend {
	handleSend: () => void;
	loadingGenrates: boolean;
}

export const ReviewAndSend: FC<IReviewAndSend> = ({
	handleSend,
	loadingGenrates,
}) => {
	// global state
	const { key, name, phone } = useRecoilValue(SelectedRecipientForPaymentState);
	const amountToSend = useRecoilValue(AmountSentToRecipientState);

	const renderBtnLabel = useMemo(() => {
		if (loadingGenrates) {
			return <Loader type="loader" className="loader-white" dimension={22} />;
		} else {
			return 'Send $' + amountToSend;
		}
	}, [amountToSend, loadingGenrates]);

	return (
		<div className="ReviewAndSend">
			<div className="ReviewAndSend__send">Send ${amountToSend}</div>
			<div onClick={() => ({})} className="ReviewAndSend__recipient-card">
				<div className="ReviewAndSend__recipient-initials">{key}</div>
				<div className="ReviewAndSend__recipient-name">{'to ' + name}</div>
				<div className="ReviewAndSend__recipient-phone">{'at ' + phone}</div>
			</div>
			<div className="ReviewAndSend__money-text">
				<input
					className="ReviewAndSend__money-text--input"
					placeholder="What's this for?"
				/>
			</div>
			<div className="ReviewAndSend__notification">
				<span>Tap Send to complete.</span>
				<span>The money will typically be available in minutes.</span>
			</div>
			<div className="ReviewAndSend__send">
				<Button
					label={renderBtnLabel}
					handleClick={handleSend}
					type="button__filled button__filled--waiting button__large"
				/>
			</div>
		</div>
	);
};
