import { REACT_APP_API_HOST as API_HOST } from 'envs';

//return access token based on API_HOST
export function getAccessToken() {
	if (/.pp./gi.test(API_HOST)) {
		return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzNzQ5YzFjZWY5ODI4NWI0NWVmODNiNSIsImNsaWVudElkIjoiNWY4NjM2MWI0ZGJkNDY4MmIzMmY0MjFlM2FjM2ZhNGUiLCJidXNpbmVzcyI6IjYzNmIzYTQ3NDY3ZGE0YmY0ZDY0ZGNjNyIsImlhdCI6MTY2ODU4NjQ4MiwiZXhwIjoxNzAwMTQ0MDgyfQ.5t6Z21BOkS3XckOCOuoupEh9u_BaR-sHcnrncAPwbwc';
	}
	if (/stage/gi.test(API_HOST)) {
		return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTE2NzE0MTJmOTRkY2UzZjExNGQ0NGMiLCJpYXQiOjE3MDA1NzQ3NjJ9.3b_KpxyOvgeAp-qUDt2T65C5hsGZYKSAuF8zxbMYnSI';
	}
	return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYzOThhY2MzNGRjYzE3Y2NhNzRmNjU5OCIsImNsaWVudElkIjoiMjZlZjhlMmJmNTExNGM1NTgwYjJjYjAzN2I5NjEyODEiLCJidXNpbmVzcyI6IjYzMTIwNDdlMTI3OTQ0YWU4ZTE0N2JmYiIsImlhdCI6MTY3MDk1MDE5NywiZXhwIjoxNzAyNDg2MTk3fQ.MLRJJA2iNsOODN5pg9tJzVCbYzmS83r_zLsY3V0iw8M';
}
