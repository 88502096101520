import { AllRoutes } from './routes';

import './App.scss';

const App = () => {
	return (
		<div className="App">
			<AllRoutes />
		</div>
	);
};

export default App;
