// API URLS
export const API_URL = {
	GENRATE_TRANSTION: '/generates/',
	SEND_MESSAGE: '/send_notification/',
	CUSTOMER_DATA: '/customer/code/',
};

export const GOOGLE_API = {
	IP: 'https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate',
};
