import { useEffect, useMemo, useState } from 'react';
import { Header, ScreenSkelton, SideBar } from '@storybook';
import { SendPayment } from './send-payment';
import 'react-tooltip/dist/react-tooltip.css';
import './main.scss';

export const Main = () => {
	const renderMainHeader = useMemo(() => <Header />, []);
	const renderSidebar = useMemo(() => <SideBar />, []);
	const prefersDarkMode = window.matchMedia(
		'(prefers-color-scheme: dark)'
	).matches;
	const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

		const handleChange = (event: MediaQueryListEvent) => {
			setIsDarkMode(event.matches);
		};

		mediaQuery.addEventListener('change', handleChange);

		return () => {
			mediaQuery.removeEventListener('change', handleChange);
		};
	}, [prefersDarkMode]);

	useEffect(() => {
		const themeMode = isDarkMode ? 'dark' : 'light';
		document.documentElement.setAttribute('data-theme', themeMode);
	}, [isDarkMode]);

	//setting theme attribute for scss files ,  so that they can load the correct css file based on the data-theme

	const themeMode = isDarkMode ? 'dark' : 'light';
	document.documentElement.setAttribute('data-theme', themeMode);
	useEffect(() => {
		document.documentElement.style.setProperty('--themeMode', themeMode);
	}, [themeMode]);

	const renderMainComponent = useMemo(
		() => (
			<div className="Main__bodyWrapper">
				<SendPayment />
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDarkMode]
	);

	return (
		<div className="Main">
			<ScreenSkelton
				header={renderMainHeader}
				sideBar={renderSidebar}
				body={renderMainComponent}
			/>
			{/* <style>{` :root { --themeMode: '${themeMode}'; } `}</style> */}
		</div>
	);
};
