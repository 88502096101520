import { ChangeEvent } from 'react';

export const adjustInputWidth = (e: ChangeEvent<HTMLInputElement>) => {
	const input = e.target;
	const textLength = input.value.length;

	// Set a minimum and maximum width (adjust these according to your design)
	const minWidth = 50;
	const maxWidth = 400;

	// Calculate the new width based on the text length
	const newWidth = Math.min(Math.max(textLength * 40, minWidth), maxWidth);

	// Set the width of the input field
	input.style.width = newWidth + 'px';
};
