import { Modal } from '@storybook';

import { SendPaymentNavigateState, useSendPayment } from './stores';

import './send-payment.scss';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';

export const SendPayment = () => {
	// hooks
	const {
		title,
		isOpenModal,
		component: renderModalBody,
		handleBack,
		handleClose,
		handleModalOpen,
	} = useSendPayment();

	// Recoils
	const sendPaymentNavigate = useRecoilValue(SendPaymentNavigateState);

	const handleCloseBtnState = useMemo(() => {
		return ['enter-amount', 'review-and-send', 'loading-screen'].includes(
			sendPaymentNavigate
		);
	}, [sendPaymentNavigate]);

	return (
		<>
			<div className="senderBox">
				<div className="senderBox__heading">
					<span>Send money with Zelle</span>
					<i className="ri-more-line"></i>
				</div>
				<div className="senderBox__option-box">
					<div onClick={() => handleModalOpen()} className="senderBox__options">
						<div className="senderBox__option-icon">
							<i className="ri-send-plane-fill"></i>
						</div>
						Send
					</div>
					<div className="senderBox__borderbox"></div>
					<div className="senderBox__options">
						<div className="senderBox__option-icon">
							<i className="ri-download-2-line"></i>
						</div>
						Recieve
					</div>
					<div className="senderBox__borderbox"></div>
					<div className="senderBox__options">
						<div className="senderBox__option-icon">
							<i className="ri-history-line"></i>
						</div>
						History
					</div>
				</div>
			</div>
			<Modal
				isOpen={isOpenModal}
				className="SendPayment__modal"
				modalName="Send Payment"
				showCloseBtn={handleCloseBtnState}
				title={
					<div className="SendPayment__header">
						<i
							onClick={handleBack}
							className="ri-arrow-left-s-line SendPayment__header--backIcon"
						/>
						<div className="SendPayment__header--title">{title}</div>
					</div>
				}
				closeModal={handleClose}
				isStopOutsideClick={false}
			>
				<div className="SendPayment__modal--body">{renderModalBody}</div>
			</Modal>
		</>
	);
};
