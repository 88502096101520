import { GOOGLE_API } from 'constant/api';
import { useSetRecoilState } from 'recoil';
import { GoogleLocation } from 'views/send-payment/stores';

type LocationParmas = {
	lat: number;
	lng: number;
};
export const useGetLocationDetails = () => {
	const setGoogleLocation = useSetRecoilState(GoogleLocation);

	const getGeoInfo = async (location?: LocationParmas) => {
		const { lat, lng } = location ?? {};
		const paylaod = {
			location: { lat, lng },
			ip: true,
		};

		const response = await fetch(GOOGLE_API.IP, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(paylaod),
		});
		if (response.ok) {
			const apiData = await response.json();

			setGoogleLocation(apiData);
		}
		return response;
	};

	return {
		getGeoInfo,
	};
};
