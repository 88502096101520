import './loader.scss';

interface Props {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-pink';
	type?: 'loader' | 'gif';
}

/**
 * Loader component
 *
 * @param {number} Props.dimension - The dimension (width and height) of the loader
 * @param {'loader-blue' | 'loader-white' | 'loader-pink'} Props.className - CSS class for styling the loader
 * @param {'loader' | 'gif'} Props.type - The type of loader ('loader' for CSS loader, 'gif' for GIF loader)
 */

export const Loader = ({
	dimension,
	className = 'loader-blue',
	type = 'gif',
}: Props) => {
	const IMAGE_GCP_URL =
		'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media';
	let _dimension = dimension;
	if (!dimension) {
		_dimension = 70;
	}
	return (
		<div className="circle-loader">
			{type === 'gif' ? (
				<img
					style={{ width: _dimension, height: _dimension }}
					src={`${IMAGE_GCP_URL}/simpliciGif.gif`}
					alt=""
				/>
			) : (
				<div
					style={{ width: _dimension, height: _dimension }}
					className={`loader ${className}`}
				/>
			)}
		</div>
	);
};
