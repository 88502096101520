import { useCallback, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import {
	AmountSentToRecipientState,
	GenratesApiState,
	IsRecipientDoneState,
	SelectedRecipientForPaymentState,
	SendPaymentNavigateState,
	UserDetailsState,
} from './states';
import {
	EnterAmount,
	LoadingScreen,
	RecipientDetails,
	ReviewAndSend,
	SelectRecipient,
	SuccessScreen,
} from '../components';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant/api';
import { useApiStatusChecker } from 'hooks/status-check/status-check';

export const useSendPayment = () => {
	const { post: genrateTraction } = useNetwork();
	const { post: sendMessage } = useNetwork();
	const userDetails = useRecoilValue(UserDetailsState);
	const { customer } = userDetails ?? {};
	const { id } = customer ?? {};

	//globle states
	const [sendPaymentNavigate, setSendPaymentNavigate] = useRecoilState(
		SendPaymentNavigateState
	);
	const setSelectedRecipientForPayment = useSetRecoilState(
		SelectedRecipientForPaymentState
	);
	const amountToSend = useRecoilValue(AmountSentToRecipientState);

	const setIsRecipientDone = useSetRecoilState(IsRecipientDoneState);
	const resetIsRecipientDone = useResetRecoilState(IsRecipientDoneState);
	const resetSendPaymentNavigate = useResetRecoilState(
		SendPaymentNavigateState
	);
	const resetSelectedRecipientForPayment = useResetRecoilState(
		SelectedRecipientForPaymentState
	);

	const selectedRecipient = useRecoilValue(SelectedRecipientForPaymentState);
	const resetAmountSentToRecipient = useResetRecoilState(
		AmountSentToRecipientState
	);
	const setGenratesCodes = useSetRecoilState(GenratesApiState);
	const { startApiStatusChecking } = useApiStatusChecker();
	const [loading, setLoading] = useState(false);

	// handle modal close function
	const handleClose = useCallback(() => {
		// eslint-disable-next-line no-console
		resetAmountSentToRecipient();
		resetSelectedRecipientForPayment();
		resetSendPaymentNavigate();
		resetIsRecipientDone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// handle modal open function
	const handleModalOpen = useCallback(() => {
		setSendPaymentNavigate('select-recipient');
	}, [setSendPaymentNavigate]);

	const handleGenrateTransition = useCallback(async () => {
		setLoading(true);
		const payload = {
			sender: {
				phone: '+14157676179',
				name: 'Austin Trombley',
				email: 'austin.trombley@satschel.com',
				// phone: '+918283847779',
				// name: 'Archana',
				// email: 'archana.kumari@satschel.com',
				notification: {
					email: true,
					phone: true,
				},
				liveness: {
					gestures: [],
				},
				face_match: false,
				order: 2,
			},
			recipient: {
				phone: selectedRecipient.phone,
				name: selectedRecipient.name,
				email: 'kushwah.archu04@gmail.com',
				notification: {
					email: true,
					phone: true,
				},

				liveness: {},
				face_match: false,
				order: 1,
			},
			amount: amountToSend ?? '1',
			callbackUrl: 'https://google.co.in/',
		};

		genrateTraction(API_URL.GENRATE_TRANSTION, payload)
			.then((resp) => {
				// eslint-disable-next-line no-console
				console.log('genrates---', resp);
				setGenratesCodes(resp);
				setTimeout(() => {
					startApiStatusChecking();
				}, 1000);
				setSendPaymentNavigate('loading-screen');
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.log('eoorr', error);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amountToSend, selectedRecipient]);

	const handleProceed = useCallback(() => {
		// sendMessage(API_URL.SEND_MESSAGE + id);
		setSendPaymentNavigate('loading-screen');
		setIsRecipientDone(true);
		setTimeout(() => {
			startApiStatusChecking();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleResendInvite = useCallback(() => {
		sendMessage(API_URL.SEND_MESSAGE + id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// handle render components
	const { isOpenModal, title, component, handleBack } = useMemo(() => {
		switch (sendPaymentNavigate) {
			case 'select-recipient':
				return {
					isOpenModal: true,
					title: 'Select recipient',
					component: (
						<SelectRecipient
							handleSelectRecipient={(item) => {
								setSelectedRecipientForPayment(item);
								setSendPaymentNavigate('enter-amount');
							}}
						/>
					),
					handleBack: handleClose,
				};
			case 'enter-amount':
				return {
					isOpenModal: true,
					title: 'Enter Amount',
					component: (
						<EnterAmount
							handleReview={() => setSendPaymentNavigate('review-and-send')}
						/>
					),
					handleBack: () => {
						setSendPaymentNavigate('select-recipient');
						resetAmountSentToRecipient();
					},
				};
			case 'review-and-send':
				return {
					isOpenModal: true,
					title: 'Review & send',
					component: (
						<ReviewAndSend
							handleSend={() => handleGenrateTransition()}
							loadingGenrates={loading}
						/>
					),
					handleBack: () => setSendPaymentNavigate('enter-amount'),
				};
			case 'loading-screen':
				return {
					isOpenModal: true,
					title: 'Processing payment',
					component: <LoadingScreen handleResend={handleResendInvite} />,
					handleBack: handleClose,
				};
			case 'recipient-details':
				return {
					isOpenModal: true,
					title: 'Recipient Details',
					component: (
						<RecipientDetails
							handleProceed={handleProceed}
							handleReject={handleClose}
						/>
					),
					handleBack: handleClose,
				};
			case 'success-screen':
				return {
					isOpenModal: true,
					title: '',
					component: <SuccessScreen />,
					handleBack: handleClose,
				};

			default:
				return {
					isOpenModal: false,
					title: '',
					component: <></>,
					handleBack: () => ({}),
				};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		loading,
		handleClose,
		resetAmountSentToRecipient,
		sendPaymentNavigate,
		setSelectedRecipientForPayment,
		setSendPaymentNavigate,
	]);

	return {
		title,
		component,
		isOpenModal,
		handleBack,
		handleClose,
		handleModalOpen,
	};
};
