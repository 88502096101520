import { Image } from '@storybook';
import './success-screen.scss';

export const SuccessScreen = () => {
	//ToDo need to shift all text messages in constant files
	return (
		<div className="verification__success">
			<div className="verification__success_img-wrapper">
				<Image fileName="success.gif" className="verification__success-img" />
			</div>
			<div className="verification__success-message">Payment Successful</div>
			<div className="verification__sub-heading">
				The identities of Both Parties have been confirmed and Transaction is
				Complete
			</div>
		</div>
	);
};
