import { FC, useMemo } from 'react';

import { RECIPIENT_LIST } from '../../constants';
import { ISelectedRecipientForPayment } from '../../stores';

import './select-recipient.scss';

interface ISelectRecipient {
	handleSelectRecipient: (item: ISelectedRecipientForPayment) => void;
}

export const SelectRecipient: FC<ISelectRecipient> = ({
	handleSelectRecipient,
}) => {
	const renderCard = useMemo(
		() =>
			RECIPIENT_LIST.map(({ key, name, phone }) => (
				<div
					key={key}
					onClick={() => handleSelectRecipient({ key, name, phone })}
					className="SelectRecipient__recipient-card"
				>
					<div className="SelectRecipient__recipient-initials">{key}</div>
					<div className="SelectRecipient__recipient-name">{name}</div>
					<div className="SelectRecipient__recipient-phone">{phone}</div>
				</div>
			)),
		[handleSelectRecipient]
	);

	return (
		<div className="select-recipeint-wrapper">
			<div className="SelectRecipient">
				<div className="search-input-wrapper">
					<i className="ri-search-line search-icon"></i>
					<input
						className="search-input"
						type="search"
						placeholder="Name, U.S. mobile, email"
					/>
				</div>
				<div className="SelectRecipient__title">Recent recipients</div>
				<div className="SelectRecipient__recipient-list">{renderCard}</div>
			</div>
		</div>
	);
};
