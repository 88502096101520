import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	GenratesApiState,
	IsRecipientDoneState,
	SendPaymentNavigateState,
	UserDetailsState,
} from 'views/send-payment/stores';
import { API_URL } from 'constant/api';
import { useNetwork } from 'hooks/network';

export const useApiStatusChecker = () => {
	const intervalId = useRef<NodeJS.Timeout>();
	const [userDetails, setUserDetailsState] = useRecoilState(UserDetailsState);
	const userDetailsRef = useRef(userDetails);
	const IsRecipientDone = useRecoilValue(IsRecipientDoneState);
	const IsRecipientDoneRef = useRef(IsRecipientDone);
	const genratesResponse = useRecoilValue(GenratesApiState);
	const setSendPaymentNavigate = useSetRecoilState(SendPaymentNavigateState);
	const genrateRef = useRef(genratesResponse);
	// hook
	const { get } = useNetwork();

	useEffect(() => {
		genrateRef.current = genratesResponse;
		IsRecipientDoneRef.current = IsRecipientDone;
		userDetailsRef.current = userDetails;
	}, [IsRecipientDone, userDetails, genratesResponse]);

	const saveTranstionData = useCallback(
		(senderDetailsState: any) => {
			const recipientPhone = (userDetailsRef.current?.user?.phone_no ?? '')
				.replace('+1', '')
				.replace('+91', '');
			const senderPhone = (senderDetailsState?.user?.phone_no ?? '')
				.replace('+1', '')
				.replace('+91', '');
			const payload = {
				sender: senderDetailsState?.user?.name ?? 'Austin Trombley',
				recipient: userDetailsRef.current?.user?.name ?? '--',
				amount: senderDetailsState?.transaction?.amount ?? '1',
				currency: 'USD',
				location: {
					sender: senderDetailsState?.customer?.device_info?.location ?? {},
					recipient:
						userDetailsRef.current?.customer?.device_info?.location ?? {},
				},
				image: senderDetailsState?.customer?.user_image ?? '',
				recipient_img: userDetailsRef.current?.customer?.user_image ?? '',
				recipient_id: userDetailsRef.current?.customer?.id ?? '',
				recipientEmail: userDetailsRef.current?.user?.email_id ?? '',
				senderEmail:
					senderDetailsState?.user?.email_id ?? 'Austin.Trombley@satschel.com',
				senderIP: senderDetailsState?.customer?.device_info?.ip ?? '',
				senderOS:
					senderDetailsState?.customer?.device_info?.['user-agent'] ?? 'IOS',
				recipientIP: userDetailsRef.current?.customer?.device_info?.ip ?? '',
				recipientOS:
					userDetailsRef.current?.customer?.device_info?.['user-agent'] ??
					'IOS',
				recipientPhone,
				senderPhone,
			};
			const url = 'https://api.satschel.com/v1/pipeline-transactions';
			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			})
				.then((resp) => {
					// To redirect to next page
					// eslint-disable-next-line no-console
					console.log('Transtion Data', resp);
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error(error);
				});
		},
		[userDetailsRef]
	);

	const checkApiStatus = useCallback(async () => {
		const apiUrl = `${API_URL.CUSTOMER_DATA}${
			!IsRecipientDoneRef.current
				? genrateRef.current.receiver
				: genrateRef.current.sender
		}`;

		const responseData = await get(apiUrl);
		if (
			responseData?.customer?.status === 'completed' ||
			responseData?.customer?.status === 'rejected' ||
			responseData?.customer?.status === 'declined'
		) {
			if (!IsRecipientDoneRef.current) {
				setUserDetailsState(responseData);
				setSendPaymentNavigate('recipient-details');
			} else {
				saveTranstionData(responseData);
				setSendPaymentNavigate('success-screen');
			}
			clearInterval(intervalId.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const startApiStatusChecking = useCallback(() => {
		// Set up an interval to call checkApiStatus every 1000 milliseconds (1 second)
		const id = setInterval(checkApiStatus, 1000);

		// Save the intervalId to state
		intervalId.current = id;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const stopApiStatusChecking = () => {
		// Clear the interval when you want to stop checking
		clearInterval(intervalId.current);
	};

	// Return the functions to start and stop API status checking
	return { startApiStatusChecking, stopApiStatusChecking, saveTranstionData };
};
